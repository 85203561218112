
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("back-office/abilities/base", function(){ return i("../abilities/base.ts");});
d("back-office/adapters/-json-api", function(){ return i("../adapters/-json-api.js");});
d("back-office/adapters/application", function(){ return i("../adapters/application.ts");});
d("back-office/adapters/user", function(){ return i("../adapters/user.ts");});
d("back-office/app", function(){ return i("../app.ts");});
d("back-office/changeset/create-changeset", function(){ return i("../changeset/create-changeset.js");});
d("back-office/changeset/extended-changeset", function(){ return i("../changeset/extended-changeset.js");});
d("back-office/changeset/immer-changeset", function(){ return i("../changeset/immer-changeset.js");});
d("back-office/changesets/action", function(){ return i("../changesets/action.ts");});
d("back-office/changesets/base-action-product", function(){ return i("../changesets/base-action-product.ts");});
d("back-office/changesets/base", function(){ return i("../changesets/base.js");});
d("back-office/changesets/forgot-password", function(){ return i("../changesets/forgot-password.js");});
d("back-office/changesets/login", function(){ return i("../changesets/login.js");});
d("back-office/changesets/product", function(){ return i("../changesets/product.ts");});
d("back-office/changesets/reset-password", function(){ return i("../changesets/reset-password.js");});
d("back-office/changesets/tag", function(){ return i("../changesets/tag.ts");});
d("back-office/changesets/user", function(){ return i("../changesets/user.ts");});
d("back-office/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("back-office/config/environment", function(){ return i("../config/environment.js");});
d("back-office/data-adapter", function(){ return i("../data-adapter.js");});
d("back-office/decorators/disable-in-fastboot", function(){ return i("../decorators/disable-in-fastboot.js");});
d("back-office/enums/applicant-kind.enum", function(){ return i("../enums/applicant-kind.enum.js");});
d("back-office/enums/base-action-product-status.enum", function(){ return i("../enums/base-action-product-status.enum.js");});
d("back-office/enums/base-action-product-type.enum", function(){ return i("../enums/base-action-product-type.enum.js");});
d("back-office/enums/education-function", function(){ return i("../enums/education-function.js");});
d("back-office/enums/gender.enum", function(){ return i("../enums/gender.enum.js");});
d("back-office/enums/job-status.enum", function(){ return i("../enums/job-status.enum.js");});
d("back-office/enums/nationality.enum", function(){ return i("../enums/nationality.enum.js");});
d("back-office/enums/professional-situation.enum", function(){ return i("../enums/professional-situation.enum.js");});
d("back-office/enums/study-level.enum", function(){ return i("../enums/study-level.enum.js");});
d("back-office/enums/tag-type.enum", function(){ return i("../enums/tag-type.enum.js");});
d("back-office/enums/target-audience.enum", function(){ return i("../enums/target-audience.enum.js");});
d("back-office/enums/type-of-teaching.enum", function(){ return i("../enums/type-of-teaching.enum.js");});
d("back-office/enums/unemployed-length.enum", function(){ return i("../enums/unemployed-length.enum.js");});
d("back-office/flash/object", function(){ return i("../flash/object.js");});
d("back-office/formats", function(){ return i("../formats.js");});
d("back-office/index", function(){ return i("../index.js");});
d("back-office/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("back-office/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("back-office/initializers/ember-data-data-adapter", function(){ return i("../initializers/ember-data-data-adapter.js");});
d("back-office/initializers/ember-data", function(){ return i("../initializers/ember-data.js");});
d("back-office/initializers/ember-simple-auth", function(){ return i("../initializers/ember-simple-auth.js");});
d("back-office/initializers/setup-ember-can", function(){ return i("../initializers/setup-ember-can.js");});
d("back-office/initializers/simple-auth-token", function(){ return i("../initializers/simple-auth-token.js");});
d("back-office/instance-initializers/ember-data", function(){ return i("../instance-initializers/ember-data.js");});
d("back-office/instance-initializers/ember-simple-auth", function(){ return i("../instance-initializers/ember-simple-auth.js");});
d("back-office/instance-initializers/head-browser", function(){ return i("../instance-initializers/head-browser.js");});
d("back-office/instance-initializers/sentry-performance", function(){ return i("../instance-initializers/sentry-performance.js");});
d("back-office/interfaces/address", function(){ return i("../interfaces/address.js");});
d("back-office/interfaces/duration", function(){ return i("../interfaces/duration.js");});
d("back-office/interfaces/og-metadata", function(){ return i("../interfaces/og-metadata.js");});
d("back-office/models/action", function(){ return i("../models/action.js");});
d("back-office/models/action", function(){ return i("../models/action.ts");});
d("back-office/models/cover", function(){ return i("../models/cover.js");});
d("back-office/models/cover", function(){ return i("../models/cover.ts");});
d("back-office/models/description", function(){ return i("../models/description.js");});
d("back-office/models/description", function(){ return i("../models/description.ts");});
d("back-office/models/document", function(){ return i("../models/document.js");});
d("back-office/models/document", function(){ return i("../models/document.ts");});
d("back-office/models/family", function(){ return i("../models/family.js");});
d("back-office/models/formator-image", function(){ return i("../models/formator-image.js");});
d("back-office/models/formator-image", function(){ return i("../models/formator-image.ts");});
d("back-office/models/module", function(){ return i("../models/module.js");});
d("back-office/models/module", function(){ return i("../models/module.ts");});
d("back-office/models/og-image", function(){ return i("../models/og-image.js");});
d("back-office/models/og-image", function(){ return i("../models/og-image.ts");});
d("back-office/models/product", function(){ return i("../models/product.js");});
d("back-office/models/product", function(){ return i("../models/product.ts");});
d("back-office/models/tag", function(){ return i("../models/tag.ts");});
d("back-office/models/testimony-image", function(){ return i("../models/testimony-image.ts");});
d("back-office/models/user", function(){ return i("../models/user.js");});
d("back-office/models/user", function(){ return i("../models/user.ts");});
d("back-office/router", function(){ return i("../router.js");});
d("back-office/serializers/-default", function(){ return i("../serializers/-default.js");});
d("back-office/serializers/-json-api", function(){ return i("../serializers/-json-api.js");});
d("back-office/serializers/-rest", function(){ return i("../serializers/-rest.js");});
d("back-office/serializers/application", function(){ return i("../serializers/application.ts");});
d("back-office/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("back-office/services/abilities", function(){ return i("../services/abilities.js");});
d("back-office/services/base-document", function(){ return i("../services/base-document.ts");});
d("back-office/services/can", function(){ return i("../services/can.js");});
d("back-office/services/changesets/action", function(){ return i("../services/changesets/action.ts");});
d("back-office/services/changesets/base-action-product", function(){ return i("../services/changesets/base-action-product.ts");});
d("back-office/services/changesets/changeset-service", function(){ return i("../services/changesets/changeset-service.js");});
d("back-office/services/changesets/product", function(){ return i("../services/changesets/product.ts");});
d("back-office/services/changesets/tag", function(){ return i("../services/changesets/tag.ts");});
d("back-office/services/changesets/user", function(){ return i("../services/changesets/user.ts");});
d("back-office/services/cookies", function(){ return i("../services/cookies.js");});
d("back-office/services/cover", function(){ return i("../services/cover.ts");});
d("back-office/services/current-changeset", function(){ return i("../services/current-changeset.js");});
d("back-office/services/current-transition", function(){ return i("../services/current-transition.js");});
d("back-office/services/current-user", function(){ return i("../services/current-user.ts");});
d("back-office/services/dialog-layer", function(){ return i("../services/dialog-layer.js");});
d("back-office/services/download-file", function(){ return i("../services/download-file.js");});
d("back-office/services/error-handler", function(){ return i("../services/error-handler.js");});
d("back-office/services/extended-store", function(){ return i("../services/extended-store.js");});
d("back-office/services/fetch", function(){ return i("../services/fetch.js");});
d("back-office/services/flash-messages", function(){ return i("../services/flash-messages.js");});
d("back-office/services/formator-image", function(){ return i("../services/formator-image.ts");});
d("back-office/services/head-data", function(){ return i("../services/head-data.js");});
d("back-office/services/head-data", function(){ return i("../services/head-data.ts");});
d("back-office/services/intl", function(){ return i("../services/intl.js");});
d("back-office/services/loading", function(){ return i("../services/loading.js");});
d("back-office/services/og-image", function(){ return i("../services/og-image.ts");});
d("back-office/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("back-office/services/page-title", function(){ return i("../services/page-title.js");});
d("back-office/services/session", function(){ return i("../services/session.js");});
d("back-office/services/session", function(){ return i("../services/session.ts");});
d("back-office/services/store-document", function(){ return i("../services/store-document.js");});
d("back-office/services/store", function(){ return i("../services/store.js");});
d("back-office/services/testimony-image", function(){ return i("../services/testimony-image.ts");});
d("back-office/session-stores/application", function(){ return i("../session-stores/application.js");});
d("back-office/tailwind/tailwind.config", function(){ return i("../tailwind/tailwind.config.js");});
d("back-office/test-access/access-token", function(){ return i("../test-access/access-token.js");});
d("back-office/transforms/boolean", function(){ return i("../transforms/boolean.js");});
d("back-office/transforms/date", function(){ return i("../transforms/date.js");});
d("back-office/transforms/number", function(){ return i("../transforms/number.js");});
d("back-office/transforms/string", function(){ return i("../transforms/string.js");});
d("back-office/types/typed-changeset", function(){ return i("../types/typed-changeset.js");});
d("back-office/utils/do-if-changeset", function(){ return i("../utils/do-if-changeset.js");});
d("back-office/utils/document-diff", function(){ return i("../utils/document-diff.ts");});
d("back-office/utils/file-action", function(){ return i("../utils/file-action.ts");});
d("back-office/utils/file-to-form-data", function(){ return i("../utils/file-to-form-data.js");});
d("back-office/utils/file-to-form", function(){ return i("../utils/file-to-form.ts");});
d("back-office/utils/generic-diff", function(){ return i("../utils/generic-diff.js");});
d("back-office/utils/intl/missing-message", function(){ return i("../utils/intl/missing-message.js");});
d("back-office/utils/intl/missing-message", function(){ return i("../utils/intl/missing-message.ts");});
d("back-office/utils/is-changeset-array", function(){ return i("../utils/is-changeset-array.js");});
d("back-office/utils/is-changeset", function(){ return i("../utils/is-changeset.js");});
d("back-office/utils/nested-changeset/data", function(){ return i("../utils/nested-changeset/data.js");});
d("back-office/utils/nested-changeset/errors", function(){ return i("../utils/nested-changeset/errors.js");});
d("back-office/utils/nested-changeset/execute", function(){ return i("../utils/nested-changeset/execute.js");});
d("back-office/utils/nested-changeset/is-dirty", function(){ return i("../utils/nested-changeset/is-dirty.js");});
d("back-office/utils/nested-changeset/is-valid", function(){ return i("../utils/nested-changeset/is-valid.js");});
d("back-office/utils/nested-changeset/validate", function(){ return i("../utils/nested-changeset/validate.js");});
d("back-office/utils/options-without-selected", function(){ return i("../utils/options-without-selected.ts");});
d("back-office/utils/reactive-changeset", function(){ return i("../utils/reactive-changeset.js");});
d("back-office/utils/sanitize-string", function(){ return i("../utils/sanitize-string.js");});
d("back-office/utils/save-file", function(){ return i("../utils/save-file.ts");});
d("back-office/utils/to", function(){ return i("../utils/to.js");});
d("back-office/utils/validate-and-map", function(){ return i("../utils/validate-and-map.js");});
d("back-office/utils/window-history-back", function(){ return i("../utils/window-history-back.js");});
d("back-office/validations/action", function(){ return i("../validations/action.ts");});
d("back-office/validations/base-action-product", function(){ return i("../validations/base-action-product.ts");});
d("back-office/validations/forgot-password", function(){ return i("../validations/forgot-password.js");});
d("back-office/validations/login", function(){ return i("../validations/login.js");});
d("back-office/validations/product", function(){ return i("../validations/product.ts");});
d("back-office/validations/reset-password", function(){ return i("../validations/reset-password.js");});
d("back-office/validations/tag", function(){ return i("../validations/tag.ts");});
d("back-office/validations/user", function(){ return i("../validations/user.ts");});
d("back-office/modifiers/did-insert", function(){ return i("../modifiers/did-insert.js");});
d("back-office/modifiers/did-update", function(){ return i("../modifiers/did-update.js");});
d("back-office/modifiers/focus-trap", function(){ return i("../modifiers/focus-trap.js");});
d("back-office/modifiers/on-click-outside", function(){ return i("../modifiers/on-click-outside.js");});
d("back-office/modifiers/will-destroy", function(){ return i("../modifiers/will-destroy.js");});
d("back-office/templates/application", function(){ return i("../templates/application.hbs");});
d("back-office/application/controller", function(){ return i("../application/controller.ts");});
d("back-office/application/route", function(){ return i("../application/route.ts");});



w._embroiderRouteBundles_ = [
  {
    names: ["404"],
    load: function() {
      return import("./_route_/404.js");
    }
  },
  {
    names: ["dashboard.actions.edit"],
    load: function() {
      return import("./_route_/dashboard.actions.edit.js");
    }
  },
  {
    names: ["dashboard.actions.index"],
    load: function() {
      return import("./_route_/dashboard.actions.index.js");
    }
  },
  {
    names: ["dashboard.actions"],
    load: function() {
      return import("./_route_/dashboard.actions.js");
    }
  },
  {
    names: ["dashboard.alerts"],
    load: function() {
      return import("./_route_/dashboard.alerts.js");
    }
  },
  {
    names: ["dashboard.exports"],
    load: function() {
      return import("./_route_/dashboard.exports.js");
    }
  },
  {
    names: ["dashboard.index"],
    load: function() {
      return import("./_route_/dashboard.index.js");
    }
  },
  {
    names: ["dashboard.learnings.edit"],
    load: function() {
      return import("./_route_/dashboard.learnings.edit.js");
    }
  },
  {
    names: ["dashboard.learnings.index"],
    load: function() {
      return import("./_route_/dashboard.learnings.index.js");
    }
  },
  {
    names: ["dashboard.learnings"],
    load: function() {
      return import("./_route_/dashboard.learnings.js");
    }
  },
  {
    names: ["dashboard.products.edit"],
    load: function() {
      return import("./_route_/dashboard.products.edit.js");
    }
  },
  {
    names: ["dashboard.products.index"],
    load: function() {
      return import("./_route_/dashboard.products.index.js");
    }
  },
  {
    names: ["dashboard.products"],
    load: function() {
      return import("./_route_/dashboard.products.js");
    }
  },
  {
    names: ["dashboard.profile"],
    load: function() {
      return import("./_route_/dashboard.profile.js");
    }
  },
  {
    names: ["dashboard.settings"],
    load: function() {
      return import("./_route_/dashboard.settings.js");
    }
  },
  {
    names: ["dashboard.tags.index"],
    load: function() {
      return import("./_route_/dashboard.tags.index.js");
    }
  },
  {
    names: ["dashboard.tags"],
    load: function() {
      return import("./_route_/dashboard.tags.js");
    }
  },
  {
    names: ["dashboard.users.create"],
    load: function() {
      return import("./_route_/dashboard.users.create.js");
    }
  },
  {
    names: ["dashboard.users.edit"],
    load: function() {
      return import("./_route_/dashboard.users.edit.js");
    }
  },
  {
    names: ["dashboard.users.index"],
    load: function() {
      return import("./_route_/dashboard.users.index.js");
    }
  },
  {
    names: ["dashboard.users"],
    load: function() {
      return import("./_route_/dashboard.users.js");
    }
  },
  {
    names: ["dashboard"],
    load: function() {
      return import("./_route_/dashboard.js");
    }
  },
  {
    names: ["forgot-password"],
    load: function() {
      return import("./_route_/forgot-password.js");
    }
  },
  {
    names: ["index"],
    load: function() {
      return import("./_route_/index.js");
    }
  },
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
  {
    names: ["logout"],
    load: function() {
      return import("./_route_/logout.js");
    }
  },
  {
    names: ["reset-password"],
    load: function() {
      return import("./_route_/reset-password.js");
    }
  },
  {
    names: ["set-password"],
    load: function() {
      return import("./_route_/set-password.js");
    }
  },
  {
    names: ["head"],
    load: function() {
      return import("./_route_/head.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"name":"back-office","version":"1.0.38-dev+b0e175e5"});
}

